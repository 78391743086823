import { createSelector } from "reselect";

const selectBuyingDecision = (state) => state.buyingDecision;

export const selectMandiRollUp = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => buyingDecision.mandiRollUp
);

export const selectFORRollUp = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => buyingDecision.FORRollUp
);

export const selectFarmerRollUp=createSelector(
  [selectBuyingDecision],
  buyingDecision=>buyingDecision.farmerRollUp
)

export const selectAvailableHubs = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => buyingDecision.availableHubs
);

export const selectAvailableMandis = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => buyingDecision.availableMandis
);

export const selectHubTableData = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => {
    return buyingDecision.hubTableData;
  }
);

export const selectActualHubTableData = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => {
    return buyingDecision.actualHubTableData;
  }
);

export const selectDecisionBranches = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => buyingDecision.decisionBranches
);
export const selectDecisionRegions = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => buyingDecision.decisionRegions
);
export const selectDecisionVarieties = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => buyingDecision.decisionVarieties
);

export const selectSelectedBranchID = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => buyingDecision.selectedBranchId
);

export const selectSelectedRegionID = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => buyingDecision.selectedRegionId
);

export const selectSelectedVarietyID = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => buyingDecision.selectedVarietyId
);

export const selectSelectedHubID = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => buyingDecision.selectedHubId
);

export const selectHubDataPostFOR = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => buyingDecision.hubDataPostFOR
);

export const selectRemarks = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => buyingDecision.remarks
);

export const selectDualRender = createSelector(
  [selectBuyingDecision],
  (buyingDecision) => buyingDecision.dualRender
);
