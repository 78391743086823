import { BuyingDecisionActionTypes } from "./buying-decision.types";

export const setRollUp = (mandiRollUp, FORRollUp,farmerRollUp) => {
  return {
    type: BuyingDecisionActionTypes.SET_ROLL_UP,
    mandiRollUp: mandiRollUp,
    FORRollUp: FORRollUp,
    farmerRollUp:farmerRollUp
  };
};

export const setAvailableHubs = (hubs) => {
  return {
    type: BuyingDecisionActionTypes.SET_AVAILABLE_HUBS,
    payload: hubs,
  };
};

export const setAvailableMandis = (mandis) => {
  return {
    type: BuyingDecisionActionTypes.SET_AVAILABLE_MANDIS,
    payload: mandis,
  };
};

export const setHubTableData = (data) => {
  return {
    type: BuyingDecisionActionTypes.SET_HUB_TABLE_DATA,
    payload: data,
  };
};

export const setActualHubTableData = (data) => {
  return {
    type: BuyingDecisionActionTypes.SET_ACTUAL_HUB_TABLE_DATA,
    payload: data,
  };
};

export const addNewHubs = (hubs) => {
  return {
    type: BuyingDecisionActionTypes.ADD_NEW_HUBS,
    payload: hubs,
  };
};

export const addnewsHubsPostFOR = (hubs) => {
  return {
    type: BuyingDecisionActionTypes.ADD_HUB_DATA_POST_FOR,
    payload: hubs,
  };
};

export const setSelectedBranchID = (id) => {
  return {
    type: BuyingDecisionActionTypes.SET_SELECTED_BRANCH_ID,
    payload: id,
  };
};

export const setSelectedRegionID = (id) => {
  return {
    type: BuyingDecisionActionTypes.SET_SELECTED_REGION_ID,
    payload: id,
  };
};

export const setSelectedVarietyID = (id) => {
  return {
    type: BuyingDecisionActionTypes.SET_SELECTED_VARIETY_ID,
    payload: id,
  };
};

export const setSelectedHubID = (id) => {
  return { type: BuyingDecisionActionTypes.SET_SELECTED_HUB_ID, payload: id };
};

export const setDecisionBranches = (data) => {
  return {
    type: BuyingDecisionActionTypes.SET_DECISION_BRANCHES,
    payload: data,
  };
};

export const setDecisionRegions = (data) => {
  return {
    type: BuyingDecisionActionTypes.SET_DECISION_REGIONS,
    payload: data,
  };
};

export const setDecisionVarieties = (data) => {
  return {
    type: BuyingDecisionActionTypes.SET_DECISION_VARIETIES,
    payload: data,
  };
};

export const setRemarks = (remarks) => {
  return {
    type: BuyingDecisionActionTypes.SET_REMARKS,
    payload: remarks,
  };
};

export const flipState = () => {
  return {
    type: BuyingDecisionActionTypes.FLIP_STATE,
  };
};
export const incrementDualRender = () => {
  console.log("actions");
  return {
    type: BuyingDecisionActionTypes.INCREMENT_DUALRENDER,
  };
};
export const setDualRender = (value) => {
  return {
    type: BuyingDecisionActionTypes.SET_DUALRENDER,
    payload: value,
  };
};

export const setHubDataPostFOR = (data) => {
  // console.log("SETTING POST DATA HUB TO REDUX");
  return {
    type: BuyingDecisionActionTypes.SET_HUB_DATA_POST_FOR,
    payload: data,
  };
};

export const setActualHubDataPostFOR = (data) => {
  // console.log("SETTING POST DATA HUB TO REDUX");
  return {
    type: BuyingDecisionActionTypes.SET_ACTUAL_HUB_DATA_POST_FOR,
    payload: data,
  };
};
