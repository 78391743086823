import { BuyingDecisionActionTypes } from "./buying-decision.types";

const INITIAL_STATE = {
  mandiRollUp: {
    suggested_price: 0,
    suggested_qty: 0,
    today_price: 0,
    today_arrival_qty: 0,
    yesterday_price: 0,
    yesterday_purchase_qty: 0,
    yesterday_arrival_qty: 0,
    revised_buy_min_price: 0,
    revised_buy_max_price: 0,
  },
  FORRollUp: {
    suggested_price: 0,
    suggested_qty: 0,
    today_price: 0,
    today_arrival_qty: 0,
    yesterday_price: 0,
    yesterday_purchase_qty: 0,
    yesterday_arrival_qty: 0,
    revised_buy_min_price: 0,
    revised_buy_max_price: 0,
  },

  farmerRollUp: {
    revised_buy_max_price: 0,
    revised_buy_min_price: 0,
    suggested_price: 0,
    suggested_qty: 0,
    today_arrival_qty: 0,
    today_price: 0,
    yesterday_arrival_qty: 0,
    yesterday_price: 0,
    yesterday_purchase_qty: 0
  },

  hubTableData: [],
  actualHubTableData: [],
  availableHubs: [],
  availableMandis: [],
  remarks: [],

  decisionBranches: [],
  decisionRegions: [],
  decisionVarieties: [],

  selectedBranchId: null,
  selectedRegionId: null,
  selectedHubId: null,
  selectedVarietyId: null,
  flip: true,
  hubDataPostFOR: [],
  actualHubDataPostFOR: [],
  dualRender: 0,
};

const buyingDecisionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BuyingDecisionActionTypes.SET_ROLL_UP:
      return {
        ...state,
        mandiRollUp: action.mandiRollUp,
        FORRollUp: action.FORRollUp,
        farmerRollUp: action.farmerRollUp
      };

    case BuyingDecisionActionTypes.SET_AVAILABLE_HUBS:
      return { ...state, availableHubs: action.payload };

    case BuyingDecisionActionTypes.SET_AVAILABLE_MANDIS:
      return { ...state, availableMandis: action.payload };

    case BuyingDecisionActionTypes.SET_HUB_TABLE_DATA:
      return { ...state, hubTableData: action.payload };

    case BuyingDecisionActionTypes.SET_ACTUAL_HUB_TABLE_DATA:
      return { ...state, actualHubTableData: action.payload };
      

    case BuyingDecisionActionTypes.ADD_NEW_HUBS:
      const updatedHubTableData = {
        region: {
          hub_details: [
            ...state.hubTableData.region.hub_details,
            ...action.payload,
          ],
        },
      };
      return {
        ...state,
        hubTableData: updatedHubTableData,
      };

    case BuyingDecisionActionTypes.ADD_HUB_DATA_POST_FOR:
      const modifiedHubDataPostFOR = [
        ...state.hubDataPostFOR,
        ...action.payload,
      ];
      return { ...state, hubDataPostFOR: modifiedHubDataPostFOR };

    case BuyingDecisionActionTypes.SET_SELECTED_BRANCH_ID:
      return { ...state, selectedBranchId: action.payload };

    case BuyingDecisionActionTypes.SET_SELECTED_REGION_ID:
      return { ...state, selectedRegionId: action.payload };

    case BuyingDecisionActionTypes.SET_SELECTED_VARIETY_ID:
      return {
        ...state,
        selectedVarietyId: parseInt(action.payload),
      };

    case BuyingDecisionActionTypes.SET_SELECTED_HUB_ID:
      return { ...state, selectedHubId: action.payload };

    case BuyingDecisionActionTypes.SET_REMARKS:
      return {
        ...state,
        remarks: action.payload,
      };

    case BuyingDecisionActionTypes.SET_DECISION_BRANCHES:
      return { ...state, decisionBranches: action.payload };

    case BuyingDecisionActionTypes.SET_DECISION_REGIONS:
      return { ...state, decisionRegions: action.payload };

    case BuyingDecisionActionTypes.SET_DECISION_VARIETIES:
      return { ...state, decisionVarieties: action.payload };

    case BuyingDecisionActionTypes.FLIP_STATE:
      const flipped = !state.flip;
      return { ...state, flip: flipped };

    case BuyingDecisionActionTypes.SET_HUB_DATA_POST_FOR:
      return { ...state, hubDataPostFOR: action.payload };
      
    case BuyingDecisionActionTypes.SET_ACTUAL_HUB_DATA_POST_FOR:
      return { ...state, actualHubDataPostFOR: action.payload };
      
    case BuyingDecisionActionTypes.SET_DUALRENDER:
      return { ...state, dualRender: action.payload };

    case BuyingDecisionActionTypes.INCREMENT_DUALRENDER:
      console.log("incrementing dualrender");
      const value = state.dualRender + 1;
      return { ...state, dualRender: value };

    default:
      return state;
  }
};

export default buyingDecisionReducer;
