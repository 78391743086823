export const BuyingDecisionActionTypes = {
  SET_ROLL_UP: "SET_ROLL_UP",

  SET_AVAILABLE_HUBS: "SET_AVAILABLE_HUBS",
  SET_AVAILABLE_MANDIS: "SET_AVAILABLE_MANDIS",

  ADD_NEW_HUBS: "ADD_NEW_HUBS",

  SET_HUB_TABLE_DATA: "SET_HUB_TABLE_DATA",
  SET_ACTUAL_HUB_TABLE_DATA: "SET_ACTUAL_HUB_TABLE_DATA",
  SET_SELECTED_BRANCH_ID: "SET_SELECTED_BRANCH_ID",
  SET_SELECTED_REGION_ID: "SET_SELECTED_REGION_ID",
  SET_SELECTED_VARIETY_ID: "SET_SELECTED_VARIETY_ID",
  SET_SELECTED_HUB_ID: "SET_SELECTED_HUB_ID",

  SET_REMARKS: "SET_REMARKS",

  SET_DECISION_BRANCHES: "SET_DECISION_BRANCHES",
  SET_DECISION_REGIONS: "SET_DECISION_REGIONS",
  SET_DECISION_VARIETIES: "SET_DECISION_VARIETIES",

  FLIP_STATE: "FLIP_STATE",
  SET_HUB_DATA_POST_FOR: "SET_HUB_DATA_POST_FOR",
  SET_ACTUAL_HUB_DATA_POST_FOR: "SET_ACTUAL_HUB_DATA_POST_FOR",
  ADD_HUB_DATA_POST_FOR:'ADD_HUB_DATA_POST_FOR',
  SET_DUALRENDER: "SET_DUALRENDER",
  INCREMENT_DUALRENDER: "INCREMENT_DUALRENDER",
};
