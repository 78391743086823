import React, { Component, Alert, useState, xlsx } from "react";
import "./arrivals-entry.styles.css";
import * as XLSX from "xlsx";

import leftArrow from "../../assets/left_arrow.svg";
import rightArrow from "../../assets/right_arrow.svg";
import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";
import { selectUserData } from "../../redux/user/user.selectors";
import {
  Slider,
  Progress,
  Spin,
  message,
  Checkbox,
  Button,
  Table,
  Modal,
  Select,
  Upload,
} from "antd";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Link,
  withRouter,
  Switch as RouteSwitch,
  Route,
} from "react-router-dom";

import API, { baseURL } from "../../api";
import moment from "moment";
import Axios from "axios";

import {
  setCurrentArrivalScreen,
  setMandiRegion,
  setMandiHub,
  setMandiMandi,
  setFORRegion,
  setFORHub,
  setFarmerRegion,
  setFarmerHub,
} from "../../redux/map/map.actions";
import {
  selectCurrentArrivalScreen,
  selectMandiValues,
  selectFORValues,
  selectMandiRegion,
  selectMandiHub,
  selectMandiMandi,
  selectFORRegion,
  selectFORHub,
  selectFarmerRegion,
  selectFarmerHub,
} from "../../redux/map/map.selectors";

import {
  setArrivalData,
  setSummaryVisibility,
  setBranches,
  setRegions,
  setHubs,
  setMandis,
  setVarieties,
  setRemainingVarietyList,
  setHubVarieties,
  setRemainingHubVarietyList,
  setBranchID,
  setRegionID,
  setHubID,
  setMandiID,
  setAllMandis,
} from "../../redux/arrival-entry/arrival-entry.actions";
import {
  selectArrivalData,
  selectSummaryScreenVisibility,
  selectBranches,
  selectRegions,
  selectHubs,
  selectMandis,
  selectVarieties,
  selectAllVarietyInfo,
  selectHubVarieties,
  selectBranchID,
  selectRegionID,
  selectHubID,
  selectMandiID,
} from "../../redux/arrival-entry/arrival-entry.selectors";

import ArrivalCategoryList from "../../components/arrival-category-list/arrival-category-list.component";
import MapItem from "../../components/map-item/map-item.component";
import CustomSelectArrival from "../../components/custom-select-arrival/custom-select-arrival.component";
import Summary from "../summary/summary.page";

/**
 * @component
 * @description Custom button component for next/previous mandi selection.
 * @property {image} src The image to be displayed on the Button
 * @property {boolean} disabled true:disables the button.
 * @property {event} onClick event to be triggered on click
 * @classdesc Child component of Arrival entry.
 * @memberof ArrivalEntry
 */
const CustomButton = (props) => {
  const { src, disabled, onClick } = props;
  return (
    <div
      className={`${disabled ? "disabled-button nav-button" : "nav-button"}`} //nav-button
      onClick={() => onClick()}
    >
      <img style={{ width: "15px" }} src={src} alt="button" />
    </div>
  );
};

const upload = (e) => {
  // Convert the FileList into an array and iterate
  Array.from(e.target.files).forEach((file) => {
    console.log("Upload");
    // Define a new file reader
    let reader = new FileReader();

    // Function to execute after loading the file
    reader.onload = () => console.log(reader.result);

    // Read the file as a text
    reader.readAsText(file);
  });
};

const FileDownLoad = require("js-file-download");

// class FileUploadButton extends Component {
//   handleFileUpload = event => {
//     console.log("Handle File Upload")
//     console.log(event.target.files[0].name);
//   }}

// function apple()
// {
//   let data="Please Click oK to Submit the file!"
//   alert(data)
// }

// const changeHandler = (event) => {
// 	setSelectedFile(event.target.files[0]);
//   console.log("Handle File Upload")
// 	setIsSelected(true);
// };

const TableColumnHeader = (props) => {
  const { title, subtitle } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          fontWeight: 400,
          color: "white",
          fontSize: "14px",
          whiteSpace: "nowrap",
          textAlign: "left",
        }}
      >
        {title}
      </div>
      <div
        style={{
          color: "#cbdfff",
          fontSize: "11px",
          fontWeight: "normal",
          textAlign: "left",
        }}
      >
        {subtitle}
      </div>
    </div>
  );
};

export const DeleteDataFromCard = (
  branchId,
  hubId,
  mandiId,
  varietyId,
  isMandi,
  isFOR,
  callback,
  setCardSpinner
) => {
  setCardSpinner(true);

  const dataToPut = {
    branch_id: branchId,
    hub_id: hubId,
    mandi_id: mandiId,
    variety_id: varietyId,
    arrival_type: isMandi ? "mandi" : isFOR ? "for" : "farmer",
    arrival_date: moment(new Date(), "DD-MM-YYYY").format("YYYY-MM-DD"),
  };

  API.put("/arrivals_delete", dataToPut)
    .then((deleteResponse) => {
      if (deleteResponse.data.status) {
        message.success("Data has been cleared successfully.");
        setCardSpinner(false);
        callback();
      } else {
        setCardSpinner(false);
        message.warning("Data could not be cleared. Please try again.");
      }
    })
    .catch((err) => {
      setCardSpinner(false);
      message.error(
        "Data could not be cleared. Please check your network connectivity."
      );
    });
};

/**
 * @component
 * @description The parent component for arrival entry page.
 *
 *
 */
class ArrivalEntry extends Component {
  state = {
    buyerType: 1,
    isSpinnerOn: false,
    isSubmitted: false,
    previousCardDataMandi: [],
    previousCardDataFOR: [],
    previousCardDataFarmer: [],
    isPriorityMandis: false,
    isPriorityCheckboxVisible: true,
    mandilist: [],
    HubListPopUp: [],
    HubListPopUpModalUse: [],
    HubListPopUpModalUseatest: [],
    isClosedCheck: false,
    isNoArrivalsCheck: false,
    isFORNoArrivalsCheck: false,
    isModalFileVisible: false,
    selectedFile: null,
    file: null,
    branchName: "",
  };

  screenTypesList = ["Mandi", "FOR", "Farmer"];

  branchNamesList; // = branches.map(item => item.territory_name);
  regionNamesList; // = regions.map(item => item.territory_name);
  hubNamesList; // = hubs.map(item => item.territory_name);
  mandiNamesList; // = mandis.map(item => item.territory_name);
  mandiupdatelist;
  // = mandis.map(item => item.territory_name);
  hubFORList;
  hubUpdateList;
  hubsListforMandi;
  forceChanges = () => {
    const { currentScreen, setCurrentArrivalScreen } = this.props;

    switch (currentScreen) {
      case "Mandi":
        const tempMandiScreen = currentScreen;
        setCurrentArrivalScreen("FOR");
        setCurrentArrivalScreen(tempMandiScreen);

        break;
      case "FOR":
        let tempFORScreen = currentScreen;
        setCurrentArrivalScreen("Mandi"); //Farmer
        setCurrentArrivalScreen(tempFORScreen);
        break;
      case "Farmer":
        const tempFarmerScreen = currentScreen;
        setCurrentArrivalScreen("Mandi");
        setCurrentArrivalScreen(tempFarmerScreen);
        break;
      default:
        break;
    }
    this.setState({ isSpinnerOn: false });
  };

  shouldComponentUpdate() {
    // Rendering the component only if
    // passed props value is changed

    if (this.props.mandis !== this.props.arrivalData) {
      this.forceUpdate();
      return true;
    } else {
      return false;
    }
  }
  /**
   * @function
   * @description Update the branch,hub and mandi names to be displayed initially.
   * @param {number} territoryTypeID territory type id that needs to be updated.
   * @memberof ArrivalEntry
   */
  updateLists = (territoryTypeID) => {
    const { branches, regions, hubs, mandis, varieties } = this.props;

    // this.setState({mandilist : mandis});
    switch (territoryTypeID) {
      case 0:
        this.branchNamesList = branches.map((item) => item.branch_name);
        this.hubNamesList = hubs.map((item) => item.hub_name);
        this.mandiNamesList = mandis.map((item) => item.mandi_name);
        this.mandiupdatelist = mandis;
        let modifiedHubData = hubs.slice(0);
        this.hubFORList = modifiedHubData;
        if (this.props.currentScreen === "FOR") {
          this.hubUpdateList = modifiedHubData;
        } else {
          modifiedHubData.push({ hub_id: null, hub_name: "All" });
          this.hubsListforMandi = modifiedHubData;
          this.hubUpdateList = modifiedHubData.map((item) => item.hub_name);
        }

        break;

      case 1:
        this.branchNamesList = branches.map((item) => item.branch_name);
        break;
      // case 2:
      //   this.regionNamesList = regions.map(item => item.territory_name);
      //   break;
      case 3:
        this.hubNamesList = hubs.map((item) => item.hub_name);
        break;
      case 4:
        this.mandiNamesList = mandis.map((item) => item.mandi_name);
        break;

      default:
        break;
    }
  };

  // called after getting mandi data
  /**
   * @function
   * @description Get previous submitted data for mandi
   * @param {number} branch_id selected branch id
   * @param {number} region_id selected region id (not required)
   * @param {number} mandi_id selected mandi id
   * @param {string} screen current screen (Mandi/FOR/Farmer)
   * @memberof ArrivalEntry
   */
  getPreviousMandiCardData = async (branch_id, region_id, mandi_id, screen) => {
    //#region get all previous data in the cards

    // const currentHeaders = {
    //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
    // };

    const prevDataParams = {
      branch_id: branch_id,
      mandi_id: mandi_id,
      arrival_type: "Mandi",
    };
    console.log("env==>", process.env.REACT_APP_ENV);
    this.setState({ isSpinnerOn: true });
    API.get("/varieties/arrivals", {
      params: prevDataParams,
    })
      .then((previousDataResponse) => {
        if (previousDataResponse.data) {
          // save previous card data in the local state.
          this.setState(
            {
              previousCardDataMandi: previousDataResponse.data.data.varieties,
            },
            () => {
              if (screen) this.props.setCurrentArrivalScreen(screen);
            }
          );

          this.forceChanges();
          this.setState({ isSpinnerOn: false });
        } else {
          message.error(
            "Could not retrieve previous card data.Please try again"
          );
          this.setState({ isSpinnerOn: false });
        }
      })
      .catch((err) => {
        this.setState({ isSpinnerOn: false });
      });
    //#endregion
  };

  // called after getting hub data
  /**
   * @function
   * @description Get previous submitted data for mandi
   * @param {number} branch_id selected branch id
   * @param {number} region_id selected region id (not required)
   * @param {number} hub_id selected hub id
   * @param {boolean} isFOR true:if the screen FOR. else false.
   * @param {string} screen current screen (Mandi/FOR/Farmer)
   * @memberof ArrivalEntry
   */
  getPreviousFarmerAndFORCardData = (
    branch_id,
    region_id,
    hub_id,
    isFOR,
    screen
  ) => {
    //#region get all previous data in the cards

    // const currentHeaders = {
    //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
    // };

    const prevDataParams = {
      branch_id: branch_id,
      hub_id: hub_id,
      arrival_type: isFOR ? "FOR" : "Farmer",
    };

    this.setState({ isSpinnerOn: true });
    API.get(`/varieties/arrivals`, {
      // headers: currentHeaders,
      params: prevDataParams,
    })
      .then((previousDataResponse) => {
        if (previousDataResponse.data) {
          isFOR
            ? console.log(
                "PREVIOUS DATA : FOR: ",
                previousDataResponse.data.data
              )
            : console.log(
                "PREVIOUS DATA : FARMER: ",
                previousDataResponse.data.data
              );

          //#region get varieties from the list in a structured format and save it into hubVarieties.
          //        this will handle adding variety cards into the FOR and Farmer screens.

          let currentHubVarietyIDList =
            previousDataResponse.data.data.varieties.map(
              (item) => item.variety_id
            );

          let currentHubVarieties = currentHubVarietyIDList.map((id) =>
            this.props.allVarietyInfo.find((item) => item.variety_id === id)
          );

          this.props.setHubVarieties(currentHubVarieties);

          //#region  resetting remainingHubvarietyNames in redux
          // this can be done easily as assigning all variety names directly to the remainingHubVarietyNames.
          // but just in case the client would want to add a variety list for a hub in future,we keep a null array
          // as the incoming varietylist for the hub. replace the null array with the varietylist names of the hub
          // in case varietylist comes with hub in the future.
          let currentHubVarietyNames = currentHubVarieties.map(
            (item) => item.variety_name
          );
          let allHubVarietyNames = this.props.allVarietyInfo.map(
            (item) => item.variety_name
          );
          let remainingHubVarietyNames = allHubVarietyNames
            .filter((item) => !currentHubVarietyNames.includes(item))
            .filter((value, index, array) => array.indexOf(value) === index);

          this.props.setRemainingHubVarietyList(remainingHubVarietyNames);
          //#endregion

          //#endregion

          // save previous card data in the local state depending upon isFOR.
          isFOR
            ? this.setState(
                {
                  previousCardDataFOR: previousDataResponse.data.data.varieties,
                },
                () => {
                  if (screen) this.props.setCurrentArrivalScreen(screen);
                }
              )
            : this.setState(
                {
                  previousCardDataFarmer:
                    previousDataResponse.data.data.varieties,
                },
                () => {
                  if (screen) this.props.setCurrentArrivalScreen(screen);
                }
              );

          this.forceChanges();

          this.setState({ isSpinnerOn: false });
        } else {
          //this.props.setHubVarieties([]);
          message.error(
            "Could not retrieve previous card data.Please try again"
          );
          this.setState({ isSpinnerOn: false });
        }
      })
      .catch((err) => {
        //  this.props.setHubVarieties([]);

        message.error("Could not retrieve previous card data.Please try again");
        this.setState({ isSpinnerOn: false });
      });
    //#endregion
  };

  /**
   * @function
   * @description Set the default priority checkbox status on initialization.
   *
   * @memberof ArrivalEntry
   */
  setPriorityCheckboxStatus = () => {
    //#region if the first hub has no priority mandis, uncheck and hide the priority mandi checkbox
    let mandisInSelectedHub = this.props.arrivalData.branch_list
      .find((item) => item.branch_id === this.props.branchID)
      .hub_list.find((item) => item.hub_id === this.props.hubID).mandi_list;

    // select priority mandis if required

    const isAtleastOnePriorityMandiPresent = mandisInSelectedHub
      .map((mandi) => mandi.is_priority_mandi)
      .includes(true);

    if (!isAtleastOnePriorityMandiPresent) {
      this.setState({
        isPriorityMandis: false,
        isPriorityCheckboxVisible: false,
      });
    }

    //#endregion
  };

  /**
   * @function
   * @description Wait for the arrival data to be fetched before calling dependent functions.
   *
   * @memberof ArrivalEntry
   */
  waitForArrivalData = () => {
    if (this.props.arrivalData) {
      this.setPriorityCheckboxStatus();
      this.getPreviousMandiCardData(
        this.props.branchID,
        this.props.regionID,
        this.props.mandiID
      );
    } else {
      setTimeout(() => this.waitForArrivalData(), 100);
    }
  };
  componentDidUpdate(newProps) {
    if (
      this.props.branches.length > 0 &&
      (this.state.branchName === "" ||
        this.state.branchName === undefined ||
        this.state.branchName === null)
    ) {
      console.log("first", this.props.branches);
      this.setState((prevState) => ({
        ...prevState,
        branchName: this.props.branches[0].branch_name,
      }));
    }
    if (newProps.arrivalData?.length !== this.props.arrivalData?.length) {
      const { branchID, regionID, mandiID } = this.props;
      this.waitForArrivalData();
      this.getPreviousMandiCardData(branchID, regionID, mandiID);
    }
  }
  componentDidMount() {
    const { branchID, regionID, mandiID, hubID } = this.props;
    this.waitForArrivalData();
    if (this.props.currentScreen === "Mandi") {
      this.getPreviousMandiCardData(branchID, regionID, mandiID);
    } else if (this.props.currentScreen === "FOR") {
      this.getPreviousFarmerAndFORCardData(branchID, null, hubID, true);
    } else if (this.props.currentScreen === "Farmer") {
      this.getPreviousFarmerAndFORCardData(branchID, null, hubID, false);
    }
  }

  getTerritories = async () => {
    API.get("/territories")
      .then(async (arrivalResponse) => {
        if (arrivalResponse.data.status) {
          setArrivalData(arrivalResponse.data.data);

          //#region save all mandis into redux
          let allMandiList = [];
          arrivalResponse.data.data.branch_list.map((branch) => {
            branch.hub_list.map((hub) => {
              if (hub.mandi_list.length !== 0) {
                hub.mandi_list.map((mandi) => {
                  let modifiedMandi = {
                    mandi_name: mandi.mandi_name,
                    mandi_id: mandi.mandi_id,
                  };
                  allMandiList.push(modifiedMandi);
                });
              }
            });
          });

          setAllMandis(allMandiList);
          //#endregion

          //set all branches information & select first branch as default
          setBranches(arrivalResponse.data.data.branch_list);
          setBranchID(arrivalResponse.data.data.branch_list[0].branch_id);
          this.setState({
            branchName: arrivalResponse.data.data.branch_list[0].branch_name,
          });

          //set all hubs under the first branch and set first hub as default.
          // for mandis : hubs are the ones with mandi_list not empty.
          // for FOR and Farmer, hubs include all items in hub_list.

          let mandiHubList =
            arrivalResponse.data.data.branch_list[0].hub_list.filter(
              (item) => item.mandi_list.length !== 0
            );

          if (this.currentScreen === "Mandi") {
            setHubs(mandiHubList);
            setHubID(mandiHubList[0].hub_id);
            let modifiedHubData = mandiHubList.slice(0);
            modifiedHubData.push({ hub_id: null, hub_name: "All" });
            this.hubUpdateList = modifiedHubData.map((item) => item.hub_name);
            this.hubsListforMandi = modifiedHubData;
          } else {
            let hubList = arrivalResponse.data.data.branch_list[0].hub_list;
            this.hubFORList = hubList.slice(0);
            setHubs(arrivalResponse.data.data.branch_list[0].hub_list);
            setHubID(
              arrivalResponse.data.data.branch_list[0].hub_list[0].hub_id
            );
          }

          //set all mandis under the first hub and set the first mandi as default
          setMandis(mandiHubList[0].mandi_list);
          setMandiID(mandiHubList[0].mandi_list[0].mandi_id);
          this.mandiupdatelist = mandiHubList[0].mandi_list;
          // this.setState({mandilist : this.mandiupdatelist});
          setVarieties(mandiHubList[0].mandi_list[0].varieties);

          // region id is only for mandis. it's inside the current selected mandi item
          setRegionID(mandiHubList[0].mandi_list[0].region_id);

          //#region  resetting remainingvarietyNames in redux
          let currentVarietyNames = mandiHubList[0].mandi_list[0].varieties.map(
            (item) => item.variety_name
          );

          if (this.allVarietyInfo !== undefined) {
            let allVarietyNames = this.allVarietyInfo.map(
              (item) => item.variety_name
            );

            if (allVarietyNames !== undefined) {
              let remainingVarietyNames = allVarietyNames.filter(
                (item) => !currentVarietyNames.includes(item)
              );

              setRemainingVarietyList(remainingVarietyNames);
            }
          }
          //#endregion

          let modifiedArrivalData = this.props.arrivalData;
          modifiedArrivalData.branch_list =
            arrivalResponse.data.data.branch_list;
          this.props.setArrivalData(modifiedArrivalData);

          // get previous card data in first mandi and store to state.
          await this.getPreviousMandiCardData(
            arrivalResponse.data.data.branch_list[0].branch_id,
            mandiHubList[0].mandi_list[0].region_id,
            mandiHubList[0].mandi_list[0].mandi_id
          );

          this.forceUpdate();
          //this can be removed after fixing the previous data getting function above.
          // this.setState({ isSpinnerOn: false });
        } else {
          this.setState({ isSpinnerOn: false });
        }
      })
      .catch((err) => {
        this.setState({ isSpinnerOn: false });
      });
  };

  render() {
    const userTableProps = {
      pagination: false,
      size: "default",
      bordered: true,
      maxHeight: "150px",
      scroll: { x: 200, y: window.innerHeight - 1150 },
    };
    const {
      setSummaryVisibility,

      setHubs,
      setMandis,
      setVarieties,
      setRemainingVarietyList,
      setBranchID,
      setRegionID,
      setHubID,
      setMandiID,
      currentScreen,
      branches,
      hubs,
      mandis,
      varieties,
      allVarietyInfo,
      branchID,
      regionID,
      hubID,
      mandiID,

      setMandiHub,
      setMandiMandi,
      setFORHub,
      setFarmerHub,
    } = this.props;

    this.updateLists(0);
    const user = this.props.userData;
    const setModalVisibility = (status, rowRecord) => {
      // const foo = {...this.hubUpdateList}
      // this.setState({HubListPopUpModalUse:foo});
      const val = this.hubUpdateList.slice(0);
      this.setState({ HubListPopUpModalUseatest: val });

      this.mandiupdatelist.map((dataDetailItem, index) => {
        dataDetailItem.isNoArrivalsCheck = false;
        dataDetailItem.isFORNoArrivalsCheck = false;
        dataDetailItem.isClosedCheck = false;
      });
      // this.setState({mandilist : this.mandiupdatelist},
      //   ()=>{
      //this.getTerritories();
      this.setState({ isSpinnerOn: true });

      API.get("/territories")
        .then((arrivalResponse) => {
          if (arrivalResponse.data.status) {
            setArrivalData(arrivalResponse.data.data);

            let tempBranchId = this.props.branchID ? this.props.branchID : 0;

            if (currentScreen === "Mandi") {
              let mandiHubList = arrivalResponse.data.data.branch_list
                .filter((item) => item.branch_id === tempBranchId)[0]
                .hub_list.filter((item) => item.mandi_list.length !== 0);

              this.mandiupdatelist = mandiHubList[0].mandi_list;
            } else if (currentScreen === "FOR") {
              this.hubFORList = arrivalResponse.data.data.branch_list.filter(
                (item) => item.branch_id === tempBranchId
              )[0].hub_list;
            }

            this.setState({ HubListPopUp: this.hubFORList });
            this.setState({ mandilist: this.mandiupdatelist });
            this.setState({ HubListPopUp: this.hubFORList }, () => {
              setTimeout(() => {
                this.setState({ isModalVisible: true });
              }, 2000);
            });
            //this can be removed after fixing the previous data getting function above.
            // this.setState({ isSpinnerOn: false });
          } else {
            this.setState({ isSpinnerOn: false });
          }
        })
        .catch((err) => {
          this.setState({ isSpinnerOn: false });
        });
      //});
    };

    const seatModalVisibility = (status, rowRecord) => {
      this.setState({ isSpinnerOn: true });
      if (currentScreen === "Mandi") {
        let mandiHubList =
          this.props.arrivalData.branch_list[0].hub_list.filter(
            (item) => item.mandi_list.length !== 0
          );
        this.mandiupdatelist = mandiHubList[0].mandi_list;
      } else if (currentScreen === "FOR") {
        this.hubFORList = this.props.arrivalData.branch_list[0].hub_list;
      }

      const val = this.hubUpdateList.slice(0);
      this.setState({ HubListPopUpModalUseatest: val });

      this.mandiupdatelist.map((dataDetailItem, index) => {
        dataDetailItem.isNoArrivalsCheck = false;
        dataDetailItem.isFORNoArrivalsCheck = false;
        dataDetailItem.isClosedCheck = false;
      });

      this.setState({ isClosedCheck: false });
      this.setState({ isNoArrivalsCheck: false });
      this.setState({ isFORNoArrivalsCheck: false });

      this.setState({ isSpinnerOn: true });
      if (currentScreen === "Mandi") {
        this.setState({ mandilist: this.mandiupdatelist }, () => {
          setTimeout(() => {
            this.setState({ isModalVisible: true });
          }, 2000);
        });
      } else if (currentScreen === "FOR") {
        this.setState({ HubListPopUp: this.hubFORList }, () => {
          setTimeout(() => {
            this.setState({ isModalVisible: true });
          }, 1000);
        });
      }

      //   this.setState({mandilist : this.mandiupdatelist},
      //     ()=>{
      //   this.getTerritories();
      //   this.setState({isSpinnerOn:true})

      //     this.setState({HubListPopUp : this.hubFORList},()=>{
      //       setTimeout(() => {
      //         this.setState({ isModalVisible: true });
      //       }, 1000);
      //     })

      // });
    };

    const setModalFileVisibility = () => {
      this.setState({ isModalFileVisible: true });
    };

    //  submitpopup: Used for Modal popup to confirm with the user whether the selected file can be submitted.
    //  onOk: postfileupload is called to sumit the json converted files to the backend [postapi]

    const confirm = Modal.confirm;
    function submitpopup(e) {
      var upload = document.Uploadform.upload.value;
      if (upload == "") message.warning("Please Select The Files");
      else {
        confirm({
          title: "Do you want to upload these Files?",
          content: "Please Click Submit to Upload the Excel Report",
          okText: "Submit",
          onOk() {
            {
              postfileupload();
            }
            // {handleSubmit()}
            //document.getElementById("uploadCaptureInputFile").value = "";
            //message.success("File successfully Uploaded!");
            return new Promise((resolve, reject) => {
              setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            }).catch(() => console.log("Oops errors!"));
          },
          onCancel() {},
        });
      }
    }

    //conversion of excel file data to json form and selecting the required credentials from the  worksheet based on the json structure .

    const readUploadFile = (e) => {
      e.preventDefault();

      if (e.target.files) {
        // file["v"]
        this.setState({ isSpinnerOn: true });
        const reader = new FileReader();
        const files = e.target.files;
        //console.log(files,"atn excel")
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data);
          //this.setState({ file: workbook });
          const sheetName = workbook.SheetNames[0];
          const sheetName1 = workbook.SheetNames[1];
          const worksheet = workbook.Sheets[sheetName];
          const worksheet1 = workbook.Sheets[sheetName1];
          const json = XLSX.utils.sheet_to_json(worksheet, { range: 7 });
          const json1 = XLSX.utils.sheet_to_json(worksheet1, { range: 7 });
          const jsontest = XLSX.utils.sheet_to_json(worksheet);
          //console.log(json,"atn json converted to range 7")
          //console.log(jsontest,"atn json converted to whole")
          //console.log(workbook,"atn workbook")
          // console.log(json1,"json1 converted")
          if (worksheet["C2"] == null) {
            message.warning("Please Select The Valid File");
            document.getElementById("uploadCaptureInputFile").value = "";
          } else if (worksheet["C2"].v != "MANDI") {
            message.warning("Please Select The Valid File");
            document.getElementById("uploadCaptureInputFile").value = "";
          } else {
            const v2 = worksheet["C2"].v;
            const v3 = worksheet["C3"].w;
            const v4 = worksheet["C4"].w;
            //const modified_date = files[0].lastModifiedDate.getDate() + "/" +(files[0].lastModifiedDate.getMonth()+1) + "/" +files[0].lastModifiedDate.getUTCFullYear()
            const modified_date =
              files[0].lastModifiedDate.toDateString() +
              " " +
              files[0].lastModifiedDate.getHours() +
              ":" +
              files[0].lastModifiedDate.getMinutes() +
              ":" +
              files[0].lastModifiedDate.getSeconds();
            var base64;
            if (e) {
              const reader1 = new FileReader();
              reader1.readAsDataURL(files[0]);
              //console.log("reader 1 ", reader1);
              reader1.onload = () => {
                base64 = reader1.result.split(",")[1];
                const dataToSend = {
                  data: {
                    //arrival_type:v2,
                    //file:base64,
                    File_name: files[0].name,
                    date_time: modified_date,
                    branch: v3,
                    arrival_date: v4,
                  },
                  Mandi: [json],
                  For: [json1],
                };
                //API.post("/arrivals", dataToSend)
                this.setState({ Apidata: dataToSend });
                //this.state = {Apidata: dataToSend };
                // console.log("base 64 ", base64,dataToSend);
              };
            }
            const concat = {
              data: {
                files,
                supply_type: v2,
                branch: v3,
                arrival_date: v4,
                json,
              },
            };
            //const Data =({data:[ files,{Arrival_Data: json}]});
            //console.log("json converted Excel Data",concat,concat.data,);
            //console.log("post data " ,)
          }
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    };

    //Json data is sent to the backend using  /upload_arrival_excel endpoint.

    const postfileupload = (e) => {
      this.setState({ isSpinnerOn: true });
      //console.log("File response",this)
      // let postfileData = this.state.Apidata;
      const formData = new FormData();
      formData.append("file", this.state.file);
      API.post("/upload_arrival_excel", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          //console.log("Response",response.data)
          this.setState({ isSpinnerOn: false });
          if (response.data.status) {
            message.success(response.data.message, 2.5);
            this.setState({ isModalFileVisible: false });
            document.getElementById("uploadCaptureInputFile").value = "";
          } else if (!response.data.status) {
            message.warning(response.data.message, 2.5);
            this.setState({ isModalFileVisible: false });
            document.getElementById("uploadCaptureInputFile").value = "";
          } else {
            message.error("Failed to upload File", 2.5);
            this.setState({ isModalFileVisible: true });
          }
        })
        .catch((err) => {
          this.setState({ isSpinnerOn: false });
          this.setState({ isModalFileVisible: false });
          //console.log(err.headers,"error",err.response.status,"space",err.message)
          document.getElementById("uploadCaptureInputFile").value = "";
          if (err.response.status === 502) {
            message.error("FAILED: Please Refresh the Application", 2.5);
          } else {
            message.error("Failed to upload File", 2.5);
          }
        });
    };

    const handleFileChange = (event) => {
      this.setState({ file: event.target.files[0] });
      //console.log("FileCheck",event.target.files[0])
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const formData = new FormData();
      formData.append("file", this.state.file);
      API.post("/upload_arrival_excel", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const handleBranchChange = (territory_id) => {
      //#region  sequential api calls
      // this.setState({ isSpinnerOn: true });
      let hubsInSelectedBranch = this.props.arrivalData.branch_list.find(
        (item) => item.branch_id === territory_id
      ).hub_list;

      // for mandis : hubs are the ones with mandi_list not empty.
      // for FOR and Farmer, hubs include all items in hub_list.

      let mandiHubList = hubsInSelectedBranch.filter(
        (item) => item.mandi_list.length !== 0
      );

      if (this.props.currentScreen === "Mandi") {
        setHubs(mandiHubList);
        setHubID(mandiHubList[0].hub_id);
        handleHubChange(territory_id, null, mandiHubList[0].hub_id, "Mandi");
      } else if (this.props.currentScreen === "FOR") {
        setHubs(hubsInSelectedBranch);
        setHubID(hubsInSelectedBranch[0].hub_id);
        handleHubChange(
          territory_id,
          null,
          hubsInSelectedBranch[0].hub_id,
          "FOR"
        );

        const valHubs = Array.from(hubsInSelectedBranch);
        this.setState({ HubListPopUpModalUse: valHubs });
        this.setState({ HubListPopUp: valHubs });
      } else if (this.props.currentScreen === "Farmer") {
        setHubs(hubsInSelectedBranch);
        setHubID(hubsInSelectedBranch[0].hub_id);
        let modifiedHubData = hubsInSelectedBranch.slice(0);
        modifiedHubData.push({ hub_id: null, hub_name: "All" });
        this.hubUpdateList = modifiedHubData;
        this.hubFORList = modifiedHubData;

        const valHubs = Array.from(hubsInSelectedBranch);
        this.setState({ HubListPopUpModalUse: valHubs });
        this.setState({ HubListPopUp: valHubs });

        handleHubChange(
          territory_id,
          null,
          hubsInSelectedBranch[0].hub_id,
          "Farmer"
        );
      }

      ////////////////////////////////////////////////////////////////////////////

      //#endregion
    };

    const handleRegionChange = (branch_id, region_id) => {
      //#region  sequential api calls
      this.setState({ isSpinnerOn: true });
      // const currentHeaders = {
      //   Authorization: "Bearer " + localStorage.getItem("accessToken"),
      // };

      //#region get varieties in the region

      API.get(`branches/${branch_id}/regions/${region_id}/`, {
        // headers: currentHeaders,
      })
        .then((varietyResponse) => {
          if (varietyResponse.data !== null) {
            setVarieties(varietyResponse.data.data.varieties);

            //#region  resetting remainingvarietyNames in redux
            let currentVarietyNames = varietyResponse.data.data.varieties.map(
              (item) => item.variety_name
            );
            let allVarietyNames = allVarietyInfo.map(
              (item) => item.variety_name
            );
            let remainingVarietyNames = allVarietyNames.filter(
              (item) => !currentVarietyNames.includes(item)
            );
            setRemainingVarietyList(remainingVarietyNames);
            //#endregion
          }
        })
        .catch((err) => {});

      //#endregion

      //#region  api to get all hubs under the 1st region

      API.get(`/branches/${branch_id}/regions/${region_id}/hubs/`, {
        // headers: currentHeaders,
      }).then((hubResponse) => {
        if (hubResponse.data.status) {
          // this.setState({ hubs: hubResponse.data.data.hubs });
          setHubs(hubResponse.data.data.hubs);
          setHubID(hubResponse.data.data.hubs[0].territory_id);

          ////////////////////////////////////////////////////////////////////////////

          handleHubChange(
            branch_id,
            region_id,
            hubResponse.data.data.hubs[0].territory_id,
            this.props.currentScreen
          );

          ///////////////////////////////////////////////////////////////////////////
        }
      });

      //#endregion

      //#endregion
    };

    const handleHubChange = (branch_id, region_id, hub_id, screen) => {
      //#region  sequential api calls
      // this.setState({ isSpinnerOn: true });

      // // in the future, if varietylist for a hub comes, replace the null array with it.
      // this.props.setHubVarieties([]); ======================================================================================================================================================================================================================================================================

      // //#region  resetting remainingHubvarietyNames in redux
      // // this can be done easily as assigning all variety names directly to the remainingHubVarietyNames.
      // // but just in case the client would want to add a variety list for a hub in future,we keep a null array
      // // as the incoming varietylist for the hub. replace the null array with the varietylist names of the hub
      // // in case varietylist comes with hub in the future.
      // let currentHubVarietyNames = [];
      // let allHubVarietyNames = this.props.allVarietyInfo.map(
      //   (item) => item.variety_name
      // );
      // let remainingHubVarietyNames = allHubVarietyNames.filter(
      //   (item) => !currentHubVarietyNames.includes(item)
      // );

      // this.props.setRemainingHubVarietyList(remainingHubVarietyNames);
      // //#endregion

      //#region get and store previous card data for FOR and farmer and store into local states
      // get previous card data for FOR
      if (screen === "FOR") {
        this.getPreviousFarmerAndFORCardData(branch_id, null, hub_id, true);
      }

      // get previous card data for farmer
      if (screen === "Farmer") {
        this.getPreviousFarmerAndFORCardData(branch_id, null, hub_id, false);
      }

      let mandisInSelectedHub = [];
      //#endregion

      let mandiSubHubList = [];
      let mandisInSelectedHub1 = this.props.arrivalData.branch_list
        .find((item) => item.branch_id === branch_id)
        .hub_list.map((skillDetail) => {
          return skillDetail.mandi_list.map((details) => {
            mandisInSelectedHub.push(details);
            return details;
          });
        });
      console.log("all ", mandisInSelectedHub1);
      //  return{
      //      mandiSubHubList = item.mandi_list.map((mandis)=>{
      //        mandi_id = mandis.mandi_id,
      //        has_arrival = has_arrival,
      //        is_closed = is_closed,
      //        is_priority_mandi= is_priority_mandi,
      //        mandi_name= mandi_name,
      //        mandi_status=mandi_status,
      //        region_id=region_id,
      //        mandi_longitude=mandi_longitude,
      //        mandi_latitude=mandi_longitude,
      //      })
      //  }

      //   });
      //         if(hub_id !== 0 )
      // {
      if (hub_id !== 0 && hub_id !== undefined) {
        mandisInSelectedHub = this.props.arrivalData.branch_list
          .find((item) => item.branch_id === branch_id)
          .hub_list.find((item) => item.hub_id === hub_id).mandi_list;
      }

      // select priority mandis if required

      const isAtleastOnePriorityMandiPresent = mandisInSelectedHub
        .map((mandi) => mandi.is_priority_mandi)
        .includes(true);

      // if atlease one priority mandi is present, set priority mandis if the box is checked
      //  and make the checkbox visible.
      // else uncheck the box (display all the mandis) and hide the checkbox.
      if (isAtleastOnePriorityMandiPresent) {
        if (this.state.isPriorityMandis === true) {
          mandisInSelectedHub = mandisInSelectedHub.filter(
            (item) => item.is_priority_mandi === true
          );
        }

        this.setState({ isPriorityCheckboxVisible: true });
      } else {
        this.setState({
          isPriorityMandis: false,
          isPriorityCheckboxVisible: false,
        });
      }

      /////////////////////////////////////

      // consider mandi change function calls only if it is a hub with mandi.
      if (mandisInSelectedHub.length !== 0) {
        console.log("hub id ", hub_id);
        if (hub_id !== 0 && hub_id !== undefined) {
          setMandis(mandisInSelectedHub);
          setMandiID(mandisInSelectedHub[0].mandi_id);
        }
        this.mandiupdatelist = mandisInSelectedHub;
        this.setState({ mandilist: mandisInSelectedHub });
        if (hub_id !== 0 && hub_id !== undefined) {
          handleMandiChange(
            branch_id,
            null,
            hub_id,
            mandisInSelectedHub[0].mandi_id
          );
        } else {
          this.setState({ isSpinnerOn: false });
        }
      }
      // }
      // else{
      //   this.setState({mandilist:mandisInSelectedHub1})

      // }

      //#endregion
    };

    const handleMandiChange = (branch_id, region_id, hub_id, mandi_id) => {
      // this.setState({ isSpinnerOn: true });
      if (mandis.length !== 0) {
        //#region  sequential api calls

        let varietiesInSelectedMandi = this.props.arrivalData.branch_list
          .find((item) => item.branch_id === branch_id)
          .hub_list.find((item) => item.hub_id === hub_id)
          .mandi_list.find((item) => item.mandi_id === mandi_id).varieties;

        setVarieties(varietiesInSelectedMandi);

        // region id is only for mandis. it's inside the current selected mandi item.
        setRegionID(
          this.props.arrivalData.branch_list
            .find((item) => item.branch_id === branch_id)
            .hub_list.find((item) => item.hub_id === hub_id)
            .mandi_list.find((item) => item.mandi_id === mandi_id).region_id
        );

        //#region  resetting remainingvarietyNames in redux
        let currentVarietyNames = varietiesInSelectedMandi.map(
          (item) => item.variety_name
        );
        let allVarietyNames = allVarietyInfo.map((item) => item.variety_name);
        let remainingVarietyNames = allVarietyNames.filter(
          (item) => !currentVarietyNames.includes(item)
        );
        setRemainingVarietyList(remainingVarietyNames);
        //#endregion

        // get previous card data in first mandi and store to state.
        this.getPreviousMandiCardData(
          branch_id,
          this.props.arrivalData.branch_list
            .find((item) => item.branch_id === branch_id)
            .hub_list.find((item) => item.hub_id === hub_id)
            .mandi_list.find((item) => item.mandi_id === mandi_id).region_id,
          mandi_id
        );

        //#endregion
      }

      // no mandis in the hub
      else {
        setMandis([]);
        setMandiID(null);
      }
      //
    };

    const handleSelectChangePopup = (value, territoryTypeID) => {
      // this will get the selection and will set the corresponding territoryID
      this.setState({ isSpinnerOn: true });
      this.setState({
        isClosedCheck: false,
        isNoArrivalsCheck: false,
        isFORNoArrivalsCheck: false,
      });
      switch (territoryTypeID) {
        case 1:
          const currentBranch = branches.filter(
            (item) => item.branch_name === value
          );
          this.setState({ branchName: currentBranch[0]?.branch_name });

          setBranchID(currentBranch[0].branch_id);
          handleBranchChange(currentBranch[0].branch_id);

          break;

        case 3:
          const currentHub = hubs.filter((item) => item.hub_name === value);
          if (value !== "All") {
            setHubID(value === "All" ? 0 : currentHub[0].hub_id);
          }
          handleHubChange(
            branchID,
            regionID,
            value === "All" ? 0 : currentHub[0].hub_id,
            this.props.currentScreen
          );

          break;
        case 4:
          const currentMandi = mandis.filter(
            (item) => item.mandi_name === value
          );
          setMandiID(currentMandi[0].mandi_id);
          handleMandiChange(
            branchID,
            regionID,
            hubID,
            currentMandi[0].mandi_id
          );

          break;

        default:
          break;
      }
    };

    const handleSelectChange = (value, territoryTypeID) => {
      // this will get the selection and will set the corresponding territoryID
      this.setState({ isSpinnerOn: true });

      switch (territoryTypeID) {
        case 1:
          const currentBranch = branches.filter(
            (item) => item.branch_name === value
          );
          this.setState({ branchName: currentBranch[0]?.branch_name });

          setBranchID(currentBranch[0].branch_id);
          handleBranchChange(currentBranch[0].branch_id);

          break;

        case 3:
          const currentHub = hubs.filter((item) => item.hub_name === value);
          setHubID(currentHub[0].hub_id);
          handleHubChange(
            branchID,
            regionID,
            currentHub[0].hub_id,
            this.props.currentScreen
          );

          break;
        case 4:
          const currentMandi = mandis.filter(
            (item) => item.mandi_name === value
          );
          setMandiID(currentMandi[0].mandi_id);
          handleMandiChange(
            branchID,
            regionID,
            hubID,
            currentMandi[0].mandi_id
          );

          break;

        default:
          break;
      }
    };

    // for handling top slider changes in screen - Mandi/FOR/Farmer
    const handleSliderChange = (value) => {
      switch (value) {
        case 1:
          this.props.setCurrentArrivalScreen("Mandi");
          //set mandi hubs.ie hubs with mandis.
          let mandiHubs = this.props.arrivalData.branch_list
            .find((item) => item.branch_id === this.props.branchID)
            .hub_list.filter((item) => item.mandi_list.length !== 0);
          this.props.setHubs(mandiHubs);
          // set hubID
          this.props.setHubID(mandiHubs[0].hub_id);
          handleHubChange(
            this.props.branchID,
            null,
            mandiHubs[0].hub_id,
            "Mandi"
          );

          break;
        case 2:
          this.setState({ isSpinnerOn: true });
          this.props.setCurrentArrivalScreen("FOR");
          API.get("/territories")
            .then((arrivalResponse) => {
              if (arrivalResponse.data.status) {
                setArrivalData(arrivalResponse.data.data);

                //#region save all mandis into redux
                let allMandiList = [];
                arrivalResponse.data.data.branch_list.map((branch) => {
                  branch.hub_list.map((hub) => {
                    if (hub.mandi_list.length !== 0) {
                      hub.mandi_list.map((mandi) => {
                        let modifiedMandi = {
                          mandi_name: mandi.mandi_name,
                          mandi_id: mandi.mandi_id,
                        };
                        allMandiList.push(modifiedMandi);
                      });
                    }
                  });
                });

                setAllMandis(allMandiList);
                //#endregion

                //set all branches information & select first branch as default
                setBranches(arrivalResponse.data.data.branch_list);
                setBranchID(this.props.branchID);

                //set all hubs under the first branch and set first hub as default.
                // for mandis : hubs are the ones with mandi_list not empty.
                // for FOR and Farmer, hubs include all items in hub_list.
                let branchList = arrivalResponse.data.data.branch_list.find(
                  (item) => item.branch_id === this.props.branchID
                );

                let mandiHubList = branchList.hub_list.filter(
                  (item) => item.mandi_list.length !== 0
                );
                if (this.currentScreen === "Mandi") {
                  setHubs(mandiHubList);
                  setHubID(mandiHubList[0].hub_id);
                  let modifiedHubData = mandiHubList.slice(0);
                  modifiedHubData.push({ hub_id: null, hub_name: "All" });
                  this.hubUpdateList = modifiedHubData.map(
                    (item) => item.hub_name
                  );
                } else {
                  this.hubFORList = arrivalResponse.data.data.branch_list.find(
                    (item) => item.branch_id === this.props.branchID
                  ).hub_list;
                  setHubs(
                    arrivalResponse.data.data.branch_list.find(
                      (item) => item.branch_id === this.props.branchID
                    ).hub_list
                  );
                  setHubID(
                    arrivalResponse.data.data.branch_list.find(
                      (item) => item.branch_id === this.props.branchID
                    ).hub_list[0].hub_id
                  );
                }

                //set all mandis under the first hub and set the first mandi as default
                setMandis(mandiHubList[0].mandi_list);
                setMandiID(mandiHubList[0].mandi_list[0].mandi_id);
                this.mandiupdatelist = mandiHubList[0].mandi_list;
                // this.setState({mandilist : this.mandiupdatelist});
                setVarieties(mandiHubList[0].mandi_list[0].varieties);
                // region id is only for mandis. it's inside the current selected mandi item
                setRegionID(mandiHubList[0].mandi_list[0].region_id);

                //#region  resetting remainingvarietyNames in redux
                let currentVarietyNames =
                  mandiHubList[0].mandi_list[0].varieties.map(
                    (item) => item.variety_name
                  );
                if (this.allVarietyInfo !== undefined) {
                  let allVarietyNames = this.allVarietyInfo.map(
                    (item) => item.variety_name
                  );
                  if (allVarietyNames !== undefined) {
                    let remainingVarietyNames = allVarietyNames.filter(
                      (item) => !currentVarietyNames.includes(item)
                    );

                    setRemainingVarietyList(remainingVarietyNames);
                  }
                }
                //#endregion

                let modifiedArrivalData = this.props.arrivalData;
                modifiedArrivalData.branch_list =
                  arrivalResponse.data.data.branch_list;
                this.props.setArrivalData(modifiedArrivalData);
                // get previous card data in first mandi and store to state.
                this.getPreviousMandiCardData(
                  arrivalResponse.data.data.branch_list[0].branch_id,
                  mandiHubList[0].mandi_list[0].region_id,
                  mandiHubList[0].mandi_list[0].mandi_id
                );

                this.forceUpdate();

                let FORHubs = arrivalResponse.data.data.branch_list.find(
                  (item) => item.branch_id === this.props.branchID
                ).hub_list;

                this.props.setHubs(FORHubs);
                // set hubID
                this.props.setHubID(FORHubs[0].hub_id);

                handleHubChange(
                  this.props.branchID,
                  null,
                  FORHubs[0].hub_id,
                  "FOR"
                );
                //this can be removed after fixing the previous data getting function above.
                // this.setState({ isSpinnerOn: false });
              } else {
                this.setState({ isSpinnerOn: false });
              }
            })
            .catch((err) => {
              console.log("catch get territories", err, err.response);
              this.setState({ isSpinnerOn: false });
            });
          //set all hubs

          break;
        case 3:
          this.props.setCurrentArrivalScreen("Farmer");
          //set all hubs
          let farmerHubs = this.props.arrivalData.branch_list.find(
            (item) => item.branch_id === this.props.branchID
          ).hub_list;
          this.props.setHubs(farmerHubs);
          // set hubID
          this.props.setHubID(farmerHubs[0].hub_id);
          handleHubChange(
            this.props.branchID,
            null,
            farmerHubs[0].hub_id,
            "Farmer"
          );

          break;
        default:
          break;
      }
    };

    //handling coming back from summary to arrival entry.
    const handleBackClickInSummary = () => {
      setSummaryVisibility(false);
      switch (currentScreen) {
        case "Mandi":
          //set mandi hubs.ie hubs with mandis.
          let mandiHubs = this.props.arrivalData.branch_list
            .find((item) => item.branch_id === this.props.branchID)
            .hub_list.filter((item) => item.mandi_list.length !== 0);
          this.props.setHubs(mandiHubs);
          // set hubID
          this.props.setHubID(mandiHubs[0].hub_id);
          handleHubChange(
            this.props.branchID,
            null,
            mandiHubs[0].hub_id,
            "Mandi"
          );

          break;
        case "FOR":
          //set all hubs
          let FORHubs = this.props.arrivalData.branch_list.find(
            (item) => item.branch_id === this.props.branchID
          ).hub_list;
          this.props.setHubs(FORHubs);
          // set hubID
          this.props.setHubID(FORHubs[0].hub_id);
          handleHubChange(this.props.branchID, null, FORHubs[0].hub_id, "FOR");

          break;
        case "Farmer":
          //set all hubs
          let farmerHubs = this.props.arrivalData.branch_list.find(
            (item) => item.branch_id === this.props.branchID
          ).hub_list;
          this.props.setHubs(farmerHubs);
          // set hubID
          this.props.setHubID(farmerHubs[0].hub_id);

          handleHubChange(
            this.props.branchID,
            null,
            farmerHubs[0].hub_id,
            "Farmer"
          );
          break;
        default:
          break;
      }
    };

    // handle priority checkbox click
    const handlePriorityMandiClick = (checked) => {
      this.setState({ isPriorityMandis: checked }, () =>
        handleHubChange(
          this.props.branchID,
          this.props.regionID,
          this.props.hubID,
          "mandi"
        )
      );
    };

    const goToNextMandi = () => {
      const currentSelectedMandiIndex = this.props.mandis.findIndex(
        (item) => item.mandi_id === this.props.mandiID
      );

      setMandiID(this.props.mandis[currentSelectedMandiIndex + 1].mandi_id);
      handleMandiChange(
        branchID,
        regionID,
        hubID,
        this.props.mandis[currentSelectedMandiIndex + 1].mandi_id
      );
    };

    const goToPreviousMandi = () => {
      const currentSelectedMandiIndex = this.props.mandis.findIndex(
        (item) => item.mandi_id === this.props.mandiID
      );

      setMandiID(this.props.mandis[currentSelectedMandiIndex - 1].mandi_id);
      handleMandiChange(
        branchID,
        regionID,
        hubID,
        this.props.mandis[currentSelectedMandiIndex - 1].mandi_id
      );
    };

    const setSlider = (name) => {
      switch (name) {
        case "Mandi":
          return 1;
        case "FOR":
          return 2;
        case "Farmer":
          return 3;
        default:
          return 1;
      }
    };

    const handleCancel = () => {
      if (currentScreen === "FOR") {
        this.setState({ HubListPopUp: this.state.HubListPopUpModalUseatest });
        this.hubUpdateList = this.state.HubListPopUpModalUseatest;
        setHubs(this.state.HubListPopUpModalUseatest);
      }

      this.setState({ isClosedCheck: false });
      this.setState({ isNoArrivalsCheck: false });
      this.setState({ isFORNoArrivalsCheck: false });
      this.setState({ isModalVisible: false });
      this.setState({ isSubmitModalVisible: false });
      this.setState({ isSpinnerOn: false });
    };

    const handlecancelpopup = () => {
      this.setState({ isSpinnerOn: true });
      console.log(" cancel popup ", this.state.isModalFileVisible);
      document.getElementById("uploadCaptureInputFile").value = "";
      this.setState({ isModalFileVisible: false }, () => {
        this.setState({ isSpinnerOn: false });
        console.log(" cancel popup1 ", this.state.isModalFileVisible);
      });
    };

    const saveManidList = (ID, typeID, status) => {
      this.setState({ isSpinnerOn: true, isSubmitted: true });
      let putData;
      if (currentScreen === "Mandi") {
        //setMandis(this.state.mandilist);
        putData = this.state.mandilist.map((item) => {
          return {
            territory_id: item.mandi_id,
            territory_type_id: currentScreen === "Mandi" ? 7 : 3,
            status: item.status === undefined ? item.mandi_status : item.status,
            has_arrival: item.no_arrivals,
            is_closed: item.is_closed,
          };
        });
      } else {
        // setHubs(this.state.HubListPopUp);
        putData = this.state.HubListPopUp.map((item) => {
          return {
            territory_id: item.hub_id,
            territory_type_id: currentScreen === "Mandi" ? 7 : 3,
            status: item.status === undefined ? item.hub_status : item.status,
            has_arrival: item.no_arrivals_for,
          };
        });
      }

      const param = { arrivals_list: putData };

      let val = true;

      if (val) {
        if (val) {
          // API.post(`/changestatus/mandi/${mandiID}`, putData, {
          API.put(`/change_arrivals_territory_status`, param)
            .then(async (statusUpdateResponse) => {
              if (statusUpdateResponse.data.status) {
                //#region update the redux store
                //               if(currentScreen === "Mandi"){
                //                 setMandis(this.state.mandilist);
                //               }
                //               else if(currentScreen === "FOR"){
                //                 setHubs(this.state.HubListPopUp);
                //               }

                // await this.getTerritories();

                //#endregion
                // this.getPreviousMandiCardData(
                //   branchID,
                //   this.props.arrivalData.branch_list
                //     .find((item) => item.branch_id === branchID)
                //     .hub_list.find((item) => item.hub_id === hubID)
                //     .mandi_list.find((item) => item.mandi_id === mandiID).region_id,
                //     mandiID
                // );
                //#endregion

                API.get("/territories").then((arrivalResponse) => {
                  if (arrivalResponse.data.status) {
                    this.props.setArrivalData(arrivalResponse.data.data);
                    //setArrivalData(arrivalResponse.data.data);

                    let tempBranchId = this.props.branchID
                      ? this.props.branchID
                      : 0;
                    let temphubId = this.props.hubID ? this.props.hubID : 0;

                    if (currentScreen === "Mandi") {
                      let mandiHubList = arrivalResponse.data.data.branch_list
                        .filter((item) => item.branch_id === tempBranchId)[0]
                        .hub_list.filter(
                          (item) => item.mandi_list.length !== 0
                        );

                      let hublist = mandiHubList.filter(
                        (item) => item.hub_id === temphubId
                      )[0].mandi_list;

                      this.mandiupdatelist = hublist;
                      setMandis(this.mandiupdatelist);
                    } else if (currentScreen === "FOR") {
                      this.hubFORList =
                        arrivalResponse.data.data.branch_list.filter(
                          (item) => item.branch_id === tempBranchId
                        )[0].hub_list;
                      setHubs(this.hubFORList);
                    }
                    console.log(
                      "mandi check 2",
                      this.mandiupdatelist,
                      mandis,
                      hubs
                    );
                    setTimeout(() => {
                      message.success(statusUpdateResponse.data.message, 2.5);
                      this.setState({
                        isSpinnerOn: false,
                        isFORNoArrivalsCheck: false,
                        isNoArrivalsCheck: false,
                        isClosedCheck: false,
                        isSubmitted: false,
                      });
                      this.forceUpdate();
                    }, 2000);

                    //this can be removed after fixing the previous data getting function above.
                    this.setState({
                      isModalVisible: false,
                      isSubmitted: false,
                    });
                  } else {
                    this.setState({ isSpinnerOn: false, isSubmitted: false });
                  }
                });

                // message.success(statusUpdateResponse.data.message,2.5);
                // this.setState({isModalVisible:false,isFORNoArrivalsCheck:false,isNoArrivalsCheck:false,isClosedCheck:false});
                // this.forceUpdate();
              } else {
                message.warning(
                  "This mandi cannot be closed as arrival data has already been entered.Please refresh the application"
                );
              }
            })
            .catch((err) => {});
        } else if (status === "no arrivals") {
          // API.post(`/changestatus/mandi/${mandiID}`, putData, {
          API.post(`/change_territory_status`, putData)
            .then((statusUpdateResponse) => {
              if (statusUpdateResponse.data.status) {
                //update the redux store
                // this.getTerritories();
              } else {
                message.warning(
                  "This mandi cannot be marked as No Arrivals as arrival data has already been entered.Please refresh the application"
                );
              }
            })
            .catch((err) => {});
        } else if (status === "open") {
          // API.delete(`/changestatus/mandi/${mandiID}`, {
          API.delete("/change_territory_status", {
            data: putData,
          })
            .then((statusUpdateResponse) => {
              if (statusUpdateResponse.data.status) {
                //update the redux store
              }
            })
            .catch((err) => {});
        }
      }
      //#endregion
    };

    const getArrivals = () => {
      let postData = {
        branch_id: branchID,
        mandi_id: mandiID,
        arrival_type: "Mandi",
      };

      API.post("/arrivals", postData)
        .then((response) => {
          if (response.data.status) {
            this.getTerritories();
            //  message.success("Data saved !", 2.5);
          } else {
            //message.error("Please try again");
            message.error(response.data.message, 2.5);
            this.setState({ isEnable: true });
          }
        })
        .catch((err) => {
          this.setState({ isEnable: true });
        });
    };

    const currentHub = hubs.filter((item) => item.hub_id === hubID);

    return !this.props.summaryIsEnabled ? (
      <Spin spinning={this.state.isSpinnerOn} tip="Loading...">
        <Modal
          title={currentScreen === "FOR" ? "Destination" : "Mandis"}
          footer={false}
          visible={this.state.isModalVisible}
          onCancel={handleCancel}
          className="varaital-modal11"
          style={{
            textAlign: "center",
            height: "400px",
          }}
          width={900}
        >
          <div>
            <div>
              <div className="region-container">
                {currentScreen !== "FOR" && (
                  <div
                    className="dropdown-container"
                    style={{ marginLeft: "25px" }}
                  >
                    <p
                      style={{
                        fontSize: "25px",
                        marginBottom: "5px",
                        color: "black",
                        marginRight: "5px",
                      }}
                    >
                      Select Hub -
                    </p>

                    <CustomSelectArrival
                      list={this.hubUpdateList}
                      //  hubs={this.hubsListforMandi}
                      handleSelectChange={handleSelectChangePopup}
                      onSelect={setMandiHub}
                      territoryTypeID={3}
                    />
                  </div>
                )}
              </div>
              {/*<div className="dropdown-container">
              <p className="dropdown-label ">Mandi</p>
              <CustomSelectArrival
                list={this.mandiNamesList}
                handleSelectChange={handleSelectChange}
                onSelect={setMandiMandi}
                territoryTypeID={4}
              />
          </div>*/}
            </div>
          </div>

          <div
            className="table-container varietal-audit-table1"
            style={{ marginBottom: 20 }}
          >
            <div className="hub-form-container1">
              {currentScreen === "Mandi" && (
                <Table
                  className="arrival-table"
                  {...userTableProps}
                  columns={[
                    {
                      key: "mandi_name",
                      title: "Mandi",
                      dataIndex: "mandi_name",
                      width: 120,
                      height: 50,
                      hidden: true,
                      visible: false,
                      className: "hide",
                      // filters: this.createFilters("territory_name"),
                      // onFilter: (value, record) => record.territory_name.includes(value),
                      // sortDirections: ["descend", "ascend"],
                      // sorter: (a, b) => a.territory_name.localeCompare(b.territory_name),
                    },

                    {
                      title: (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "white",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                            paddingRight: "13px",
                          }}
                        >
                          <TableColumnHeader title="Closed Mandi" />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              paddingLeft: "6px",
                            }}
                          >
                            <Checkbox
                              checked={this.state.isClosedCheck}
                              onChange={(e) => {
                                const modifiedData =
                                  this.state.mandilist &&
                                  this.state.mandilist.length
                                    ? this.state.mandilist.map((item) => {
                                        return {
                                          ...item,
                                          is_closed: e.target.checked,
                                          no_arrivals: e.target.checked
                                            ? false
                                            : item.no_arrivals,
                                          status: e.target.checked
                                            ? "closed"
                                            : "open",
                                          mandi_status: e.target.checked
                                            ? "closed"
                                            : "open",
                                        };
                                      })
                                    : [];
                                // setMandis(modifiedData);

                                if (e.target.checked) {
                                  this.setState({
                                    isNoArrivalsCheck: !e.target.checked,
                                  });
                                }
                                this.setState({
                                  mandilist: modifiedData,
                                  isClosedCheck: e.target.checked,
                                });
                              }}
                            ></Checkbox>
                          </div>
                        </div>
                      ),
                      key: "is_closed",
                      dataIndex: "is_closed",

                      width: 50,
                      render: (value, row) => {
                        return (
                          <div>
                            <Checkbox
                              checked={
                                row.mandi_status === "closed" ? true : false
                              }
                              onClick={(e) => {
                                let count = 0;
                                const modifiedData = this.state.mandilist.map(
                                  (item, index) => {
                                    if (item.mandi_id === row.mandi_id) {
                                      item.checked && count++;
                                      return {
                                        ...item,
                                        is_closed: e.target.checked,
                                        no_arrivals: e.target.checked
                                          ? false
                                          : row.no_arrivals,
                                        status: e.target.checked
                                          ? "closed"
                                          : "open",
                                        mandi_status: e.target.checked
                                          ? "closed"
                                          : "open",
                                      };
                                    } else {
                                      return { ...item };
                                    }
                                  }
                                );
                                if (e.target.checked === false) {
                                  this.setState({ isClosedCheck: false });
                                } else {
                                  this.setState({ isNoArrivalsCheck: false });
                                }
                                //setMandis(modifiedData);
                                this.setState({ mandilist: modifiedData });
                              }}
                            />
                          </div>
                        );
                      },
                      visible: false,
                    },

                    {
                      title: (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "white",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                            paddingRight: "13px",
                          }}
                        >
                          <TableColumnHeader title="No Arrivals" />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              paddingLeft: "6px",
                            }}
                          >
                            <Checkbox
                              checked={this.state.isNoArrivalsCheck}
                              onChange={(e) => {
                                const modifiedData =
                                  this.state.mandilist &&
                                  this.state.mandilist.length
                                    ? this.state.mandilist.map((item) => {
                                        return {
                                          ...item,
                                          no_arrivals: e.target.checked,
                                          is_closed: e.target.checked
                                            ? false
                                            : item.is_closed,
                                          status: e.target.checked
                                            ? "no arrivals"
                                            : "open",
                                          mandi_status: e.target.checked
                                            ? "no arrivals"
                                            : "open",
                                        };
                                      })
                                    : [];

                                // setMandis(modifiedData);
                                if (e.target.checked) {
                                  this.setState({
                                    isClosedCheck: !e.target.checked,
                                  });
                                }
                                this.setState({
                                  mandilist: modifiedData,
                                  isNoArrivalsCheck: e.target.checked,
                                });
                              }}
                            ></Checkbox>
                          </div>
                        </div>
                      ),
                      key: "no_arrivals",
                      dataIndex: "no_arrivals",
                      width: 50,
                      render: (value, row) => {
                        return (
                          <div>
                            <Checkbox
                              checked={
                                row.mandi_status === "no arrivals"
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                let count = 0;
                                const modifiedData = this.state.mandilist.map(
                                  (item, index) => {
                                    if (item.mandi_id === row.mandi_id) {
                                      item.checked && count++;
                                      return {
                                        ...item,
                                        no_arrivals: e.target.checked,
                                        is_closed: e.target.checked
                                          ? false
                                          : row.is_closed,
                                        status: e.target.checked
                                          ? "no arrivals"
                                          : "open",
                                        mandi_status: e.target.checked
                                          ? "no arrivals"
                                          : "open",
                                      };
                                    } else {
                                      return { ...item };
                                    }
                                  }
                                );

                                if (e.target.checked === false) {
                                  this.setState({ isNoArrivalsCheck: false });
                                } else {
                                  this.setState({ isClosedCheck: false });
                                }
                                //setMandis(modifiedData);
                                this.setState({ mandilist: modifiedData });
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  ]}
                  dataSource={this.state.mandilist}
                  pagination={false}
                  scroll={{ y: window.innerHeight - 165 }}
                />
              )}
            </div>
            {currentScreen === "FOR" && (
              <div className="hub-form-container1">
                <Table
                  className="arrival-table"
                  {...userTableProps}
                  columns={[
                    {
                      key: "hub_name",
                      title: "Hub",
                      dataIndex: "hub_name",
                      width: 120,
                      height: 50,
                    },

                    {
                      title: (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "white",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                            paddingRight: "13px",
                          }}
                        >
                          <TableColumnHeader title="No Arrivals" />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              paddingLeft: "6px",
                            }}
                          >
                            <Checkbox
                              checked={this.state.isFORNoArrivalsCheck}
                              onChange={(e) => {
                                const modifiedData =
                                  this.state.HubListPopUp &&
                                  this.state.HubListPopUp.length
                                    ? this.state.HubListPopUp.map((item) => {
                                        return {
                                          ...item,
                                          no_arrivals_for: e.target.checked,
                                          hub_status: e.target.checked
                                            ? "no arrivals"
                                            : "open",
                                        };
                                      })
                                    : [];

                                //setHubs(modifiedData);

                                this.setState({
                                  HubListPopUp: modifiedData,
                                  isFORNoArrivalsCheck: e.target.checked,
                                });
                              }}
                            ></Checkbox>
                          </div>
                        </div>
                      ),
                      key: "no_arrivals_for",
                      dataIndex: "no_arrivals_for",
                      width: 50,
                      render: (value, row) => {
                        return (
                          <div>
                            <Checkbox
                              checked={
                                row.hub_status === "no arrivals" ? true : false
                              }
                              onClick={(e) => {
                                let count = 0;
                                const modifiedData =
                                  this.state.HubListPopUp.map((item, index) => {
                                    if (item.hub_id === row.hub_id) {
                                      item.checked && count++;
                                      return {
                                        ...item,
                                        has_arrival: e.target.checked,
                                        no_arrivals_for: e.target.checked,
                                        hub_status: e.target.checked
                                          ? "no arrivals"
                                          : "open",
                                      };
                                    } else {
                                      return { ...item };
                                    }
                                  });
                                if (e.target.checked === false) {
                                  this.setState({
                                    isFORNoArrivalsCheck: false,
                                  });
                                }
                                //  setHubs(modifiedData);
                                this.setState({ HubListPopUp: modifiedData });
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  ]}
                  dataSource={this.state.HubListPopUp}
                  pagination={false}
                  scroll={{ y: window.innerHeight - 165 }}
                />
              </div>
            )}
          </div>
          <div className="btn-container">
            <Button
              onClick={(e) => {
                this.setState({ isModalVisible: false });
                saveManidList();
              }}
              disabled={this.state.isSubmitted}
              className="btn-container"
              style={{
                padding: "2px 8px !important",
                width: "fit-content !important",
                backgroundColor: "rgb(32, 52, 106)",
                borderRadius: "20px",
                color: "white",
                position: "absolute",
                bottom: "2%",
                right: "3.5%",
              }}
            >
              {" "}
              Submit{" "}
            </Button>
          </div>
        </Modal>

        <div
          className="arrival-page-container"
          // style={{ height: window.innerHeight - 85 }}
        >
          <div className="map-container">
            <div
              className="summary-container"
              onClick={() => setSummaryVisibility(true)}
            >
              <div className="summary">
                {currentScreen === "Mandi" ? (
                  <div className="completed-container">
                    {
                      this.props.mandis.filter(
                        (item) =>
                          item.mandi_status === "no arrivals" ||
                          item.mandi_status === "closed" ||
                          (item.mandi_status === "open" &&
                            item.has_arrival === true)
                      ).length
                    }{" "}
                    /{this.props.mandis.length} completed
                    <div className="progress-bar">
                      <Progress
                        width={17}
                        type="circle"
                        showInfo={false}
                        percent={
                          (this.props.mandis.filter(
                            (item) =>
                              item.mandi_status === "no arrivals" ||
                              item.mandi_status === "closed" ||
                              (item.mandi_status === "open" &&
                                item.has_arrival === true)
                          ).length /
                            this.props.mandis.length) *
                          100
                        }
                      />
                    </div>
                  </div>
                ) : null}
                <span className="summary-text">See Summary &#10095;</span>
              </div>
            </div>

            <div
              className="summary-container1"
              style={{ marginTop: "60px", fontWeight: "bold" }}
              onClick={() => setModalVisibility(true)}
            >
              <div className="summary1">
                <span className="summary-text">
                  {currentScreen === "FOR" ? "Destination" : "Mandis"}
                </span>
              </div>
            </div>
            <MapItem
              centerPosition={
                currentHub.length !== 0 &&
                currentHub[0].hub_latitude &&
                currentHub[0].hub_longitude
                  ? {
                      lat: currentHub[0].hub_latitude,
                      lng: currentHub[0].hub_longitude,
                    }
                  : null
              }
              markers={this.props.currentScreen === "Mandi" ? mandis : hubs}
              handleSelectChange={handleSelectChange}
              getTerritories={this.getTerritories}
            />
            <div className="map-right-container">
              <div className="input-container">
                <div className="new-custom-slider">
                  <Slider
                    tooltipVisible={false}
                    value={setSlider(currentScreen)}
                    min={1}
                    max={2}
                    step={1}
                    onChange={(value) => handleSliderChange(value)}
                  />
                  <div className="caption">
                    <p
                      className={`${
                        currentScreen === "Mandi" ? "selected" : ""
                      }`}
                    >
                      Mandi
                    </p>
                    <p
                      className={`${currentScreen === "FOR" ? "selected" : ""}`}
                    >
                      FOR
                    </p>
                    {/* <p
                      className={`${
                        currentScreen === "Farmer" ? "selected" : ""
                      }`}
                    >
                      Farmer
                    </p> */}
                  </div>
                </div>
                {currentScreen === "Mandi" && (
                  <div className="region-container">
                    <div className="dropdown-container">
                      <p className="dropdown-label ">Branch</p>
                      <CustomSelectArrival
                        key="1"
                        list={this.branchNamesList}
                        handleSelectChange={handleSelectChange}
                        territoryTypeID={1}
                      />
                    </div>

                    {/* <div className="dropdown-container">
                      <p className="dropdown-label ">Region</p>
                      <CustomSelectArrival
                        list={this.regionNamesList}
                        handleSelectChange={handleSelectChange}
                        onSelect={setMandiRegion}
                        territoryTypeID={2}
                      />
                    </div> */}

                    <div className="dropdown-container">
                      <p className="dropdown-label ">Hub</p>
                      <CustomSelectArrival
                        list={this.hubNamesList}
                        handleSelectChange={handleSelectChange}
                        onSelect={setMandiHub}
                        territoryTypeID={3}
                      />
                    </div>

                    <div className="dropdown-container">
                      <p className="dropdown-label ">Mandi</p>
                      <CustomSelectArrival
                        list={this.mandiNamesList}
                        handleSelectChange={handleSelectChange}
                        onSelect={setMandiMandi}
                        territoryTypeID={4}
                      />
                    </div>
                    {this.props.mandis.length > 0 &&
                    this.props.mandis.filter(
                      (item) => item.is_priority_mandi === true
                    ).length > 0 ? (
                      <Checkbox
                        className={`${
                          this.state.isPriorityCheckboxVisible
                            ? "priority-checkbox"
                            : "hidden-checkbox"
                        } `}
                        checked={this.state.isPriorityMandis}
                        onChange={(e) =>
                          handlePriorityMandiClick(e.target.checked)
                        }
                      >
                        Priority Mandis
                      </Checkbox>
                    ) : null}

                    {this.props.mandis.length > 0 ? (
                      <div className="navigation-container">
                        <CustomButton
                          disabled={
                            this.props.mandis.length > 0
                              ? this.props.mandis[0].mandi_id ===
                                this.props.mandiID
                              : false
                          }
                          onClick={() => goToPreviousMandi()}
                          src={leftArrow}
                        />
                        <CustomButton
                          disabled={
                            this.props.mandis.length > 0
                              ? this.props.mandis[this.props.mandis.length - 1]
                                  .mandi_id === this.props.mandiID
                              : false
                          }
                          onClick={() => goToNextMandi()}
                          src={rightArrow}
                        />
                      </div>
                    ) : null}
                  </div>
                )}
                {currentScreen === "FOR" && (
                  <div className="hub-container">
                    <div className="dropdown-container">
                      <p className="dropdown-label ">Branch</p>
                      <CustomSelectArrival
                        list={this.branchNamesList}
                        handleSelectChange={handleSelectChange}
                        territoryTypeID={1}
                      />
                    </div>

                    {/* <div className="dropdown-container">
                      <p className="dropdown-label ">Region</p>
                      <CustomSelectArrival
                        list={this.regionNamesList}
                        onSelect={setFORRegion}
                        handleSelectChange={handleSelectChange}
                        territoryTypeID={2}
                      />
                    </div> */}

                    <div className="dropdown-container">
                      <p className="dropdown-label ">Destination</p>
                      <CustomSelectArrival
                        list={this.hubNamesList}
                        onSelect={setFORHub}
                        handleSelectChange={handleSelectChange}
                        territoryTypeID={3}
                      />
                    </div>
                  </div>
                )}

                {currentScreen === "Farmer" && (
                  <div className="hub-container">
                    <div className="dropdown-container">
                      <p className="dropdown-label ">Branch</p>
                      <CustomSelectArrival
                        list={this.branchNamesList}
                        handleSelectChange={handleSelectChange}
                        territoryTypeID={1}
                      />
                    </div>

                    {/* <div className="dropdown-container">
                      <p className="dropdown-label ">Region</p>
                      <CustomSelectArrival
                        list={this.regionNamesList}
                        onSelect={setFarmerRegion}
                        handleSelectChange={handleSelectChange}
                        territoryTypeID={2}
                      />
                    </div> */}

                    <div className="dropdown-container">
                      <p className="dropdown-label ">Hub</p>
                      <CustomSelectArrival
                        list={this.hubNamesList}
                        onSelect={setFarmerHub}
                        handleSelectChange={handleSelectChange}
                        territoryTypeID={3}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className=".arrival-category-list-container"
            style={{ height: "635px", overflowY: "auto" }}
          >
            <ArrivalCategoryList
              currentSelectedMandiId={mandiID}
              currentMandis={mandis}
              previousCardDataMandi={
                this.state.previousCardDataMandi.length !== 0
                  ? this.state.previousCardDataMandi
                  : null
              }
              previousCardDataFOR={
                this.state.previousCardDataFOR.length !== 0
                  ? this.state.previousCardDataFOR
                  : null
              }
              previousCardDataFarmer={
                this.state.previousCardDataFarmer.length !== 0
                  ? this.state.previousCardDataFarmer
                  : null
              }
              mandis={mandis}
              varieties={varieties}
              hubVarieties={this.props.hubVarieties}
              getPreviousDataMandi={() =>
                this.getPreviousMandiCardData(branchID, null, mandiID)
              }
              getPreviousDataFOR={() =>
                this.getPreviousFarmerAndFORCardData(
                  branchID,
                  null,
                  hubID,
                  true
                )
              }
              getPreviousDataFarmer={() =>
                this.getPreviousFarmerAndFORCardData(
                  branchID,
                  null,
                  hubID,
                  false
                )
              }
            />
          </div>
        </div>
        {/* <div >
                <div className="row">
                    <form>
                        <div className="download-button-container" style={{bottom: "17%",marginRight: "-50px" }}>
                            <input type="file" name="Upload" multiple/>
                        </div>
                        <div className="download-button-container" style={{bottom: "7%",marginRight: "150px" }}                 shape="round"
                icon="download"
                size="large">
                            <button className="btn btn-primary"  type="submit" >Upload</button>
                        </div>
                    </form>
                </div>
            </div> */}

        <div className="download-button-container">
          {/*<Button onClick={() => setModalFileVisibility(true)}
                type="primary"
                shape="round"
                //icon="download"
                size="large"
                style={{ marginRight: "5px" }}
              >*/}

          <Modal
            title="Select Files"
            footer={false}
            visible={this.state.isModalFileVisible}
            onCancel={handlecancelpopup}
            //className="varaital-modal11"
            style={{
              textAlign: "center",
              height: "400px",
            }}
            width={550}
          >
            <div
              style={{
                fontSize: "15px",
                color: "red",
              }}
            >
              File Name should be in below format : <br />
              Branchname_YYYYMMDD.xlsm (Eg: Jaipur_20240703.xlsm)
            </div>
            <br></br>
            <div>
              <form
                name="Uploadform"
                style={{
                  fontSize: "20px",
                  marginBottom: "5px",
                  color: "black",
                  marginRight: "50px",
                }}
              >
                <input
                  type="file"
                  id="uploadCaptureInputFile"
                  required={true}
                  accept=".xlsm"
                  name="upload"
                  //onChange={readUploadFile}
                  onChange={handleFileChange}
                />
              </form>
              {/* {this.fileData()} */}
            </div>
            <div className="btn-container">
              <Button
                onClick={submitpopup}
                //onClick={() => alert("Please Click oK to Submit the file!")}
                disabled={this.state.isSubmitted}
                className="btn-container"
                style={{
                  padding: "2px 8px !important",
                  width: "fit-content !important",
                  backgroundColor: "rgb(32, 52, 106)",
                  borderRadius: "20px",
                  color: "white",
                  position: "absolute",
                  bottom: "20%",
                  right: "3.5%",
                }}
              >
                Upload
              </Button>
            </div>
          </Modal>

          {process.env.REACT_APP_ENV !== "production" && (
            <>
              <Button
                disabled={
                  canUserEdit(
                    user,
                    this.props.branchID,
                    FeatureCodes.ARRIVALS_CLOSE_MANDI
                  )
                    ? false
                    : true
                }
                onClick={() => setModalFileVisibility(true)}
                type="primary"
                shape="round"
                icon="upload"
                size="large"
                style={{ marginRight: "5px" }}
              >
                <div style={{ float: "right" }}>
                  <div style={{ marginTop: "-7px", color: "#b4c8dc" }}>
                    Upload
                  </div>
                  <div
                    style={{
                      fontSize: "11px",
                      lineHeight: "7px",
                      color: "#b4c8dc",
                    }}
                  >
                    excel
                  </div>
                </div>
              </Button>
              {/* <form onSubmit={handleSubmit}>
                <input type="file" onChange={handleFileChange} />
                <button type="submit">Upload</button>
              </form> */}
              <Button
                disabled={
                  canUserEdit(
                    user,
                    this.props.branchID,
                    FeatureCodes.ARRIVALS_CLOSE_MANDI
                  )
                    ? false
                    : true
                }
                onClick={() => {
                  this.setState({ isSpinnerOn: true });
                  // API.get(
                  //   `/download_arrival_excel_temp`
                  // )
                  //   .then((fileresponse) => {
                  //     if (fileresponse.data.status) {
                  //#region file download
                  Axios({
                    url: `${baseURL}/download_arrival_excel_temp`,
                    method: "GET",
                    params: { branch_name: this.state.branchName },
                    responseType: "arraybuffer",
                  })
                    .then((response) => {
                      if (response.status !== 204) {
                        const date = new Date();
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(
                          2,
                          "0"
                        );
                        const day = String(date.getDate()).padStart(2, "0");
                        FileDownLoad(
                          response.data,
                          `${this.state.branchName}_${year}${month}${day}.xlsm`
                        );
                      } else {
                        message.warning("File has no data.");
                      }
                      this.setState({ isSpinnerOn: false });
                    })
                    .catch((err) => {
                      message.error("File cannot be downloaded");
                      this.setState({ isSpinnerOn: false });
                    });
                  //#endregion
                  //   } else {
                  //     message.warning("File has no data");
                  //     this.setState({ isSpinnerOn: false });
                  //   }
                  // })
                  // .catch((err) => {
                  //   message.error("Error downloading file");
                  //   this.setState({ isSpinnerOn: false });
                  // });
                }}
                type="primary"
                shape="round"
                icon="download"
                size="large"
              >
                <div style={{ float: "right" }}>
                  <div style={{ marginTop: "-7px", color: "#b4c8dc" }}>
                    Download
                  </div>
                  <div
                    style={{
                      fontSize: "11px",
                      lineHeight: "7px",
                      color: "#b4c8dc",
                    }}
                  >
                    template
                  </div>
                </div>
              </Button>
            </>
          )}
        </div>
      </Spin>
    ) : (
      <div style={{ marginTop: "-5px" }}>
        <Summary handleBackClickInSummary={handleBackClickInSummary} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setArrivalData: (data) => dispatch(setArrivalData(data)),

    setCurrentArrivalScreen: (screenName) =>
      dispatch(setCurrentArrivalScreen(screenName)),
    setBranches: (branches) => dispatch(setBranches(branches)),
    setRegions: (regions) => dispatch(setRegions(regions)),
    setHubs: (hubs) => dispatch(setHubs(hubs)),
    setMandis: (mandis) => dispatch(setMandis(mandis)),

    setVarieties: (varieties) => dispatch(setVarieties(varieties)),
    setRemainingVarietyList: (data) => dispatch(setRemainingVarietyList(data)),

    setHubVarieties: (data) => dispatch(setHubVarieties(data)),
    setRemainingHubVarietyList: (data) =>
      dispatch(setRemainingHubVarietyList(data)),

    setBranchID: (id) => dispatch(setBranchID(id)),
    setRegionID: (id) => dispatch(setRegionID(id)),
    setHubID: (id) => dispatch(setHubID(id)),
    setMandiID: (id) => dispatch(setMandiID(id)),

    setMandiRegion: (name) => dispatch(setMandiRegion(name)),
    setMandiHub: (name) => dispatch(setMandiHub(name)),
    setMandiMandi: (name) => dispatch(setMandiMandi(name)),
    setFORRegion: (name) => dispatch(setFORRegion(name)),
    setFORHub: (name) => dispatch(setFORHub(name)),
    setFarmerRegion: (name) => dispatch(setFarmerRegion(name)),
    setFarmerHub: (name) => dispatch(setFarmerHub(name)),
    setSummaryVisibility: (isEnabled) =>
      dispatch(setSummaryVisibility(isEnabled)),
  };
};

const mapStateToProps = createStructuredSelector({
  arrivalData: selectArrivalData,
  userData: selectUserData,
  currentScreen: selectCurrentArrivalScreen,
  summaryIsEnabled: selectSummaryScreenVisibility,
  branches: selectBranches,
  regions: selectRegions,
  hubs: selectHubs,
  mandis: selectMandis,
  allVarietyInfo: selectAllVarietyInfo,
  branchID: selectBranchID,
  regionID: selectRegionID,
  hubID: selectHubID,
  mandiID: selectMandiID,

  varieties: selectVarieties,
  hubVarieties: selectHubVarieties,

  mandiRegion: selectMandiRegion,
  mandiHub: selectMandiHub,
  mandiMandi: selectMandiMandi,

  FORRegion: selectFORRegion,
  FORHub: selectFORHub,

  farmerRegion: selectFarmerRegion,
  farmerHub: selectFarmerHub,

  mandiStates: selectMandiValues,
  FORStates: selectFORValues,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArrivalEntry)
);
